<template>
  <v-card id="account-setting-card">
    <div id="callLogs" style="padding: 30px">
      <h1>Callogs</h1>
      <v-btn class="ma-2" outlined color="teal" @click="tabeRe()"> <v-icon>mdi-flash</v-icon>Refresh </v-btn>
      <v-app id="inspire">
        <v-card>
          <v-card-title>
            Call Logs
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="myitems"
            :search="search"
            sort-field="durations"
            :loading="!myitems.length"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.type="{ item }">
              <v-chip :color="getColor(item.type)" dark>
                {{ item.type }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-app>
    </div>
  </v-card>
</template>
<style scoped>
.red {
  background-color: red !important;
}
.blue {
  background-color: blue !important;
}
.green {
  background-color: green !important;
}
</style>
<script>
import { doc, getDoc } from 'firebase/firestore'
import { dbRef, callLogClick } from '../../../../firebaseConfig'
import { getUID, getCurrentDeviceSelected } from '../../../../localStorage'
export default {
  name: 'CallLogs',
  data() {
    return {
      search: '',
      isLoading: false,
      myitems: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'DeviceID', value: 'deviceID' },
        { text: 'Call Durations', value: 'durations' },
        { text: 'Number', value: 'number' },
        { text: 'Type', value: 'type' },
        { text: 'Time', value: 'time' },
      ],
      desserts: [
        {
          deviceID: 'Frozen Yogurt',
          durations: 159,
          name: 6.0,
          carbs: 24,
          number: 4.0,
          time: '1%',
        },
      ],
    }
  },
  mounted: function () {
    // this.tabeRe();
    callLogClick()
    this.getCallLogsList()
  },
  methods: {
    async getCallLogsList() {
      this.$store.commit('LOADER', true)
      this.isLoading = true
      let uid = getUID()
      const myDoc = doc(dbRef, 'callLogs', uid, getCurrentDeviceSelected(), uid + '-' + getCurrentDeviceSelected())
      getDoc(myDoc)
        .then(res => {
          let listOfApps = res.data()
          Object.values(listOfApps).map(i => {
            this.myitems.push(i)
          })
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
        .finally(res => {
          this.isLoading = false
          this.$store.commit('LOADER', false)
        })
    },
    getColor(type) {
      console.log(type)
      if (type == 'OUTGOING') {
        return 'blue'
      } else if (type == 'INCOMING') {
        return 'green'
      } else {
        return 'red'
      }
    },
    async tabeRe() {
      // this.myD();
      console.log('inside tablere')
      // var x = await this.myD();
      //  console.log(x);
      //  this.myitems = this.myitems.slice().reverse();
      //  let d=this.myD();
      // d.then(data=>console.log("then"+data));
      //this.myitems = this.myitems.reverse;
      //    this.myD().then(data=>{
      //        console.log("mydatw"+data);
      //    })
    },
  },
}
</script>